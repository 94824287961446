import React, { Fragment } from 'react';
import { DRINK, FOOD } from '@/constants/constants';
import { textReferences } from '@/constants/textsReferences';
import { getTextFromTextList } from '@/utils/handlers/immutableUtils';
import TitleValance from '@/components/title-valance';
import { valanceTypes } from '@/components/valance';
import TicketItem from '@/components/ticket-item';


export const sortingFunction = (itema, itemb) => {
  const productIdA = parseInt(itema.productId, 10);
  const productIdB = parseInt(itemb.productId, 10);

  if(productIdA === productIdB){
    const idPriceBySizeA = parseInt(itema.idPriceBySize, 10);
    const idPriceBySizeB = parseInt(itemb.idPriceBySize, 10);
    return idPriceBySizeA - idPriceBySizeB;
  }
  return productIdA - productIdB;
}

export const sortTicket = (ticketItems) => {
  const ticket = [];
  const ordered = [];
  // split into two
  ticketItems.forEach(item => {
    const { sent2kitchen } = item;
    if(sent2kitchen){
      ordered.push(item);
    }else{
      ticket.push(item);
    }
  });
  // sorting by productid and size
  ordered.sort((itema, itemb) => {
    const productIdA = parseInt(itema.productId, 10);
    const productIdB = parseInt(itemb.productId, 10);

    if(productIdA === productIdB){
      const idPriceBySizeA = parseInt(itema.idPriceBySize, 10);
      const idPriceBySizeB = parseInt(itemb.idPriceBySize, 10);
      return idPriceBySizeA - idPriceBySizeB;
    }
    return productIdA - productIdB;
  })
  ticket.sort((itema, itemb) => {
    const productIdA = parseInt(itema.productId, 10);
    const productIdB = parseInt(itemb.productId, 10);

    if(productIdA === productIdB){
      const idPriceBySizeA = parseInt(itema.idPriceBySize, 10);
      const idPriceBySizeB = parseInt(itemb.idPriceBySize, 10);
      return idPriceBySizeA - idPriceBySizeB;
    }
    return productIdA - productIdB;
  })
  
  return {ticket, ordered};
}

const countUnitsOfAProduct = ({ ticket, productId }) => {
  let elements = ticket.filter(item => `${item.productId}` === `${productId}`)
  return elements.length;
}

const getReferencedProduct = ({ products, productId}) => {
  return products.find(item => `${item.productId}` === `${productId}`)
}



const getTicketNormalized = ({ products, ticket}) => {
  let ticketNormalized = [];

  ticket.forEach(itemTicket => {
    const { idPriceBySize, productId } = itemTicket;
    const isNotNormalizedYet = ticketNormalized.find(item => {
      const res = `${item.product.productId}` === `${productId}` && item.ticketInfo.find(itemPrice => `${itemPrice.idPriceBySize}` === `${idPriceBySize}`); 
      return res;
    })
    const units = countUnitsOfAProduct({ ticket, productId });
    const product = getReferencedProduct({ products, productId })
    if (!isNotNormalizedYet) {
      ticketNormalized.push({ product, units, ticketInfo: [itemTicket] })
    } else {
      const lastEntryIndex = ticketNormalized.findIndex(item => `${item.product.productId}` === `${productId}` && item.ticketInfo.find(itemPrice => `${itemPrice.idPriceBySize}` === `${idPriceBySize}`))
      if(lastEntryIndex && lastEntryIndex >= 0){
        ticketNormalized[lastEntryIndex].ticketInfo.push(itemTicket)
      }
    }
  })


  return ticketNormalized;
}

export const showTicketListOf = ({
  appTexts, 
  ticket, 
  products, 
  currentLanguage, 
  businessId, 
  tableId, 
  kind, 
  onMinus, 
  addProduct2Customize, 
  onAdd, 
  sendToKitchen, 
  allowActions=true
}) =>{
  let valanceText = null;
  if (kind === FOOD){
    valanceText = getTextFromTextList(appTexts, textReferences.READY_TO_ORDER_FOOD_TITLE_VALANCE, currentLanguage);
  } else if (kind === DRINK){
    valanceText = getTextFromTextList(appTexts, textReferences.READY_TO_ORDER_DRINK_TITLE_VALANCE, currentLanguage);
  }
  const buttonOrderText = getTextFromTextList(appTexts, textReferences.READY_TO_ORDER_BUTTON_SINGLE, currentLanguage);

  let titleValanceAppeared = 0;
  if(!ticket) {
    return null;
  }

  const ticketNormalized = getTicketNormalized({ products, ticket});

  // filter by kind

  return ticketNormalized.map(({ product, ticketInfo, units }, index) => {
    titleValanceAppeared += 1;
    const isAboutThisKind = product.kind === kind;
    if (!isAboutThisKind) {
      return null;
    }
    return (
      <Fragment key={product.productId}>
        {titleValanceAppeared <= 1 &&
          <TitleValance
            titleText={valanceText}
            valanceType={valanceTypes.UNDERLINE}
            classWrapper="mimBold Section"
          />
        }
        <TicketItem
          buttonOrderText={buttonOrderText}
          appTexts={appTexts}
          businessId={businessId}
          tableId={tableId}
          currentLanguage={currentLanguage}
          kind={kind}
          product={product}
          ticketInfo={ticketInfo}
          units={units}
          onMinus={onMinus}
          addProduct2Customize={addProduct2Customize}
          onAdd={onAdd}
          sendToKitchen={sendToKitchen}
          allowActions={allowActions}
        />
      </Fragment>
    );
  })
}

export const transform2CustomizeQuestions = (idPQAnswers) => {
  const res = {};
  if(idPQAnswers){
    const tokkens = idPQAnswers.split(',');
    tokkens.forEach(tokken => {
      const keyValueTokkens = tokken.split(':');
      const key = keyValueTokkens[0];
      const value = parseInt(keyValueTokkens[1], 10);
      res[key] = value;
    });
  }
  return res;
}

export const sortBySections = (products) => {
  const productsRest = {};

  products.forEach(product => {
    const { section } = product;
    let idSection = 0;
    if(section){
      idSection = section.idSection;
    }
    if(!(productsRest[idSection])){
      productsRest[idSection] = [];
    }

    productsRest[idSection].push(product);
  });
  return productsRest;
}

export const isACustomizableProduct = (product) => {
  const haveExtras = product.extras;
  const {minExtras2Choose, maxExtras2Choose, sides, minSides2Choose, maxSides2Choose, questionAnswers } = product;
  const haveSides = sides;
  const haveQA = questionAnswers;

  if(
    ((haveExtras && haveExtras.length > 0) && (minExtras2Choose > 0 && maxExtras2Choose >= minExtras2Choose)) ||
    ((haveSides && haveSides.length > 0) && (minSides2Choose > 0 && maxSides2Choose >= minSides2Choose)) ||
    (haveQA && haveQA.length > 0)){
    return true;
  }
  return false;
}
